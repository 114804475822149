/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroAlt from 'components/elements/HeroAlt'
import Videos from 'components/elements/Videos'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout extraSpacing >
      <SEO yoast={yoast} path={path} />

      <HeroAlt video fields={acf.banner} />

      <Videos className="mt-lg-5 py-lg-5 py-3" />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...videoPageFragment
    }
  }
`

export default PageTemplate
